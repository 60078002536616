

.timeline-bg:after {
  content:"";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 40px;
  border-left: 2px dotted gray;
  transform: translate(-50%);
}
.event {
  padding-left: 20px;
  margin-left: 14px;
  
}

ul.timeline li:first-child{
  list-style: none;
  margin-top: -40px;
}
ul.timeline li
{
  position: relative;
  color: #888;
  margin-left: -24px;
  z-index: 2;
  margin-bottom: 30px;
}

::marker {
  color:salmon;
  font-size: 1.5em;
}

/* ul.timeline li:before
{
    content: "";
    display: inline-block;
    height: 3em;
    width: 3px;
    background: #aaaa;
    margin: 0;
    padding: 0;
    margin-left: -4.5px;
    position: absolute;
    left: -12px;
    top: -0.4em;
    z-index: -1;
} */
ul {
  margin-top: 30px;
  border-left: dotted 2px;
  border-color: lightslategray;
  margin-left: 70px;
}
.event-card {
  border-radius: 4px;
  box-shadow: 3px 3px 5px lightslategray;
  border: solid lightgray 1px;
  margin-right: 30px;
}
.date-time {
  text-align: center;
  position: absolute;
  left: 8px;
  font-size:12px;
  margin-left: -80px;
  top: 5px;
}
.date-time .date {
  color: mediumslateblue;
  font-size: 14px;
  font-weight: bold;
}
.date-time .month {
  font-size: 12px;
  color: salmon;
  font-weight: bold;
}
.date-time .year {
  font-size: 12px;
  color: black;
}
.date-time .time {
  font-size: 10px;
}
ul.timeline:before
{
  content: "●";
  display: inline-block;
  font-size: 30px;
  margin: -30px;
  margin-left: -16px;
  padding: 0;
  position: relative;
  left: -34px;
  top: -10px;
  color: mediumslateblue;
}

ul.timeline:after
{
  content: "●";
  display: inline-block;
  font-size: 30px;
  margin: 0px;
  margin-left: -16px;
  padding: 0;
  position: relative;
  left: -34px;
  top: 10px;
  color: mediumslateblue;
}

.salmon-bg .button-inner {
  background: salmon !important;
}
:root {
  --ion-color-new: #ffd9cc;
  --ion-color-new-rgb: 255,217,204;
  --ion-color-new-contrast: gray;
  --ion-color-new-contrast-rgb: 0,0,0;
  --ion-color-new-shade: #e0bfb4;
  --ion-color-new-tint: #ffddd1;
}

.ion-color-new {
  --ion-color-base: var(--ion-color-new);
  --ion-color-base-rgb: var(--ion-color-new-rgb);
  --ion-color-contrast: var(--ion-color-new-contrast);
  --ion-color-contrast-rgb: var(--ion-color-new-contrast-rgb);
  --ion-color-shade: var(--ion-color-new-shade);
  --ion-color-tint: var(--ion-color-new-tint);
}
.textarea {
  min-height: 75px;
}
.textarea textarea {
  min-height: 75px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 50px;
}
.about-header {
  text-align: center;
}
.ion-page {
  padding-bottom: 50px;
}